import _http from './_http';

const AdminUserServices = {
  getAll: (request) => {
    const { pagination, query } = request;
    const requestUrl = '/api/admin/v1/users';
    const params = {
      page: pagination.current - 1,
      limit: pagination.pageSize,
      query: query,
    };
    return _http.get(requestUrl, params);
  },
  createAdminUser: (request) => {
    const { address, email, fullName, isEnabled, phoneNumbers, roles } = request;
    const requestUrl = '/api/admin/v1/users';
    const requestBody = {
      address: address,
      email: email,
      fullName: fullName,
      isEnabled: isEnabled,
      phoneNumbers: phoneNumbers,
      roles: roles,
    };
    return _http.postJson(requestUrl, requestBody);
  },
  getAdminUserById: (id) => {
    const requestUrl = `/api/admin/v1/users/${id}`;
    return _http.get(requestUrl);
  },
  updateAdminUser: (id, request) => {
    const { address, email, fullName, isEnabled, phoneNumbers, roles } = request;
    const requestUrl = `/api/admin/v1/users/${id}`;
    const requestBody = {
      address: address,
      email: email,
      fullName: fullName,
      isEnabled: isEnabled,
      phoneNumbers: phoneNumbers,
      roles: roles,
    };
    return _http.putJson(requestUrl, requestBody);
  },
  deleteAdminUser: (id) => {
    const requestUrl = `/api/admin/v1/users/${id}`;
    return _http.del(requestUrl);
  },
  getCurrentAdminUser: () => {
    const requestUrl = '/api/admin/v1/users/me';
    return _http.get(requestUrl);
  },
};

export default AdminUserServices;
