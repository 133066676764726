import { useState, useEffect } from 'react';

const useBreakPoint = () => {
  const [deviceWidth, setDeviceWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleWindowResize = () => setDeviceWidth(window.innerWidth);
    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return { deviceWidth };
};

export default useBreakPoint;
