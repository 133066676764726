import React, { Component } from 'react';
import { Switch, Route, BrowserRouter as Router, Redirect } from 'react-router-dom';

import routes from './utils/routes';
import KomJeyLayout from './components/layout/layout';
import fire from './config/fire';
import './res/scss/style.scss';
class App extends Component {
  state = {
    user: null,
    isLoading: true,
  };
  componentDidMount() {
    this.authListener();
  }
  authListener() {
    fire.auth().onAuthStateChanged(this.onAuthStateChange);
  }
  onAuthStateChange = async (user) => {
    const state = { user, isLoading: false };
    this.setState(state);
  };
  render() {
    const { user, isLoading } = this.state;

    if (isLoading) {
      return <div></div>;
    }
    return (
      <>
        {user ? (
          <KomJeyLayout />
        ) : (
          <Router>
            <React.Suspense fallback={<div></div>}>
              <Switch>
                {routes.map((route, idx) => (
                  <Route
                    path={route.path}
                    component={route.component}
                    exact
                    key={idx}
                  />
                ))}
                <Redirect from="/" to="/login" />
              </Switch>
            </React.Suspense>
          </Router>
        )}
      </>
    );
  }
}

export default App;
