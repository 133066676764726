import React from 'react';

const Login = React.lazy(() => import('../pages/login/index.js'));
const ResetPassword = React.lazy(() => import('../pages/reset_password/index.js'));
const routes = [
  { path: '/login', component: Login },
  { path: '/reset-password', component: ResetPassword },
];

export default routes;
