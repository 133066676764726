import styled from 'styled-components';
import { Layout, Menu } from 'antd';

const Slider = styled(Layout.Sider)`
  border-right: 1px solid #f0f0f0;
  .ant-menu-inline,
  .ant-menu-vertical,
  .ant-menu-vertical-left {
    border-right: none;
  }
`;

const Content = styled(Layout.Content)`
  height: calc(100vh - 64px) !important;
  padding: 0 0px 10px 0px;
  background-color: white;
  &.ant-layout.ant-layout-has-sider > .ant-layout,
  .ant-layout.ant-layout-has-sider > .ant-layout-content {
    overflow-x: scroll;
  }
`;

const MenuItem = styled(Menu.Item)`
  display: flex;
  text-align: right;
  align-items: center;
  height: 50px;
  &.ant-menu-item {
    &.ant-menu-item-selected {
      background-color: #e6f7ff;
    }
  }
`;

const AppContainer = styled.div`
  padding: 16px;
`;

export { Slider, Content, MenuItem, AppContainer };
