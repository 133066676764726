import React from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';

const LoadingIndicator = ({ size = 'large', ...props }) => {
  return (
    <SpinWrapper {...props}>
      <Spin size={size} />
    </SpinWrapper>
  );
};

const SpinWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default LoadingIndicator;
