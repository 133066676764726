import React from 'react';

import Images from 'utils/images';

const Image = ({ src, alt, ...other }) => {
  const { svg } = Images.query(src);
  return (
    <picture>
      <img src={svg} alt={alt} {...other} />
    </picture>
  );
};
export default Image;
