import firebase from 'firebase';

let firebaseConfig;

const xmlHttp = new XMLHttpRequest();
xmlHttp.open('GET', '/config/config.json', false);
xmlHttp.send(null);
try {
  firebaseConfig = JSON.parse(xmlHttp.responseText);
} catch (e) {
  firebaseConfig = {
    apiKey: 'AIzaSyAHoSmQy_Mp_TIa10RnKLBPTbnFMYl8wTY',
    authDomain: 'komjey-dev.firebaseapp.com',
    databaseURL: 'https://komjey-dev.firebaseio.com',
    projectId: 'komjey-dev',
    storageBucket: 'komjey-dev.appspot.com',
    messagingSenderId: '1023273177872',
    appId: '1:1023273177872:web:5401e4de16d00ce9594b95',
    measurementId: 'G-BBZJ8NHR3Q',
  };
}

const fire = firebase.initializeApp(firebaseConfig);

export default fire;
