const HandleSelectedMenuItem = () => {
  const keys = window.location.pathname.split('/');
  let openedKey = `/${keys[1]}`;

  let selectedKey = '';
  if (`/${keys[1]}/${keys[2]}` === '//undefined') {
    selectedKey = '/';
  } else if (`/${keys[1]}/${keys[2]}` === `/${keys[1]}/undefined`) {
    selectedKey = `/${keys[1]}`;
  } else {
    selectedKey = `/${keys[1]}/${keys[2]}`;
  }

  return { openedKey, selectedKey };
};

export default HandleSelectedMenuItem;
