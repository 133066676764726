import React, { useState, useEffect } from 'react';
import { Layout, Dropdown, Menu, Button, message } from 'antd';
import * as firebase from 'firebase';
import styled from 'styled-components';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  DownOutlined,
  BellOutlined,
} from '@ant-design/icons';

import Image from 'components/Share/image';
import bo_img from 'config/bo_img.json';
import messages from 'i18n/en-US.json';
import avatar from 'res/images/photo/avatar.png';
import AdminUserServices from 'services/admin_user_services';
import { RoutePath } from 'routes';

const logOut = () => {
  firebase
    .auth()
    .signOut()
    .then(() => {
      message.success(messages.label_logged_out_successfully);
    })
    .catch(() => {
      message.error(messages.error_something_went_wrong);
    });
};
const settings = (
  <Menu>
    <Menu.Item>
      <a href={RoutePath.user_profile}>{messages.label_user_profile}</a>
    </Menu.Item>
    <Menu.Item key="0" onClick={logOut}>
      {messages.action_logout}
    </Menu.Item>
  </Menu>
);

const notifications = <Menu></Menu>;
const Header = ({ collapsed, onFoldClick }) => {
  const [userName, setUserName] = useState('');
  useEffect(() => {
    (async function fetchData() {
      const response = await AdminUserServices.getCurrentAdminUser();
      if (response.ok) {
        setUserName(response.data.fullName);
      }
    })();
  }, []);
  return (
    <CustomHeader className="site-layout-background">
      <LogoWrapper>
        <a href="/">
          <Image src={bo_img.komjeyLogo} width="100%" />
        </a>
      </LogoWrapper>
      <FoldWrapper>
        <div onClick={onFoldClick}>
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </div>
      </FoldWrapper>
      <HeaderActionWrapper>
        <ActionItem>
          <Dropdown
            overlay={notifications}
            trigger={['click']}
            placement="bottomCenter"
          >
            <BellOutlined />
          </Dropdown>
        </ActionItem>
        <ActionItem>
          <Dropdown overlay={settings} trigger={['click']} placement="bottomCenter">
            <Button style={{ border: 'none' }} className="ant-dropdown-link">
              <img src={avatar} alt="" />
              <span className="padding-0-10">{userName}</span>
              <DownOutlined />
            </Button>
          </Dropdown>
        </ActionItem>
      </HeaderActionWrapper>
    </CustomHeader>
  );
};

const LogoWrapper = styled.div`
  width: 16vw;
  justify-content: center;
  display: flex;
  border-right: 1px solid #f0f0f0;
`;

const FoldWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  padding-left: 25px;
  svg {
    height: 25px;
    width: 25px;
  }
`;

const HeaderActionWrapper = styled.div`
  margin-right: 40px;
`;

const CustomHeader = styled(Layout.Header)`
  background-color: ${({ theme }) => theme.white};
  border-bottom: 1px solid ${({ theme }) => theme.whiteSmoke};
  display: flex;
  justify-content: space-between;
  padding: 0;
`;

const ActionItem = styled.span`
  padding: 0px 10px;
`;
export default Header;
