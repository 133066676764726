import React from 'react';
import {
  DashboardOutlined,
  SolutionOutlined,
  TeamOutlined,
  PercentageOutlined,
  BankOutlined,
  BookOutlined,
} from '@ant-design/icons';

import roles from './access_roles';
import { RoutePath } from 'routes';
import messages from 'i18n/en-US.json';

const MenuData = [
  {
    key: '/',
    label: messages.label_dashboard,
    icon: <DashboardOutlined />,
    route: '/',
    permission: [roles.admin, roles.loan_approval, roles.repayment_management],
    subMenu: [],
  },
  {
    key: '/loan-application',
    label: messages.label_loan_application,
    icon: <SolutionOutlined />,
    subMenu: [
      {
        key: RoutePath.active_loan,
        label: messages.label_active_loan,
        route: RoutePath.active_loan,
        permission: [roles.admin, roles.repayment_management],
      },
      {
        key: RoutePath.approval,
        label: messages.label_all_loans,
        route: RoutePath.approval,
        permission: [roles.admin, roles.loan_approval],
      },
    ],
  },
  {
    key: '/user-authorization',
    label: messages.label_user_authorization,
    icon: <TeamOutlined />,
    subMenu: [
      {
        key: RoutePath.admin_user_route,
        label: messages.label_back_office,
        route: RoutePath.admin_user_route,
        permission: [roles.admin, roles.admin_user_management],
      },
      {
        key: RoutePath.consumer_route,
        label: messages.label_mobile_app,
        route: RoutePath.consumer_route,
        permission: [roles.admin, roles.client_user_management],
      },
      {
        key: RoutePath.ban_records,
        label: messages.label_ban_record,
        route: RoutePath.ban_records,
        permission: [roles.admin, roles.client_user_management],
      },
    ],
  },
  {
    key: '/loan-configuration',
    label: messages.label_loan_configuration,
    icon: <PercentageOutlined />,
    subMenu: [
      {
        key: RoutePath.loan_configuration_payday,
        label: messages.label_pay_day,
        route: RoutePath.loan_configuration_payday,
        permission: [roles.admin],
      },
      {
        key: RoutePath.loan_configuration_emi,
        label: messages.label_emi,
        route: RoutePath.loan_configuration_emi,
        permission: [roles.admin],
      },
    ],
  },
  {
    key: '/bank',
    label: messages.label_bank,
    icon: <BankOutlined />,
    subMenu: [
      {
        key: RoutePath.bank_options,
        label: messages.label_bank_options,
        route: RoutePath.bank_options,
        permission: [roles.admin],
      },
      {
        key: RoutePath.komjey_bank_accounts,
        label: messages.label_komjey_bank_accounts,
        route: RoutePath.komjey_bank_accounts,
        permission: [roles.admin],
      },
    ],
  },
  {
    key: '/admin-app-configurations',
    label: messages.label_admin_app_configuration,
    icon: <BookOutlined />,
    subMenu: [
      {
        key: `${RoutePath.admin_app_configuration}/CONTACTS`,
        label: messages.label_contacts,
        route: `${RoutePath.admin_app_configuration}/CONTACTS`,
        permission: [roles.admin],
      },
      {
        key: `${RoutePath.admin_app_configuration}/KOMJEY_MESSENGER`,
        label: messages.label_komjey_messenger,
        route: `${RoutePath.admin_app_configuration}/KOMJEY_MESSENGER`,
        permission: [roles.admin],
      },
      {
        key: `${RoutePath.admin_app_configuration}/SERVICE_TERMS`,
        label: messages.label_service_terms,
        route: `${RoutePath.admin_app_configuration}/SERVICE_TERMS`,
        permission: [roles.admin],
      },
      {
        key: `${RoutePath.admin_app_configuration}/FAQ`,
        label: messages.label_faq,
        route: `${RoutePath.admin_app_configuration}/FAQ`,
        permission: [roles.admin],
      },
      {
        key: `${RoutePath.admin_app_configuration}/PRIVACY_POLICY`,
        label: messages.label_privacy_policy,
        route: `${RoutePath.admin_app_configuration}/PRIVACY_POLICY`,
        permission: [roles.admin],
      },
      {
        key: `${RoutePath.admin_app_configuration}/DATA_TERMS`,
        label: messages.label_data_terms,
        route: `${RoutePath.admin_app_configuration}/DATA_TERMS`,
        permission: [roles.admin],
      },
      {
        key: `${RoutePath.admin_app_configuration}/HOW_IT_WORKS`,
        label: messages.label_how_it_works,
        route: `${RoutePath.admin_app_configuration}/HOW_IT_WORKS`,
        permission: [roles.admin],
      },
    ],
  },
];

export default MenuData;
