const theme = {
  primary: '#3a85b0',
  white: '#ffffff',
  orange: '#faad14',
  dodgerBlue: '#1890ff',
  whiteSmoke: '#f0f0f0',
  black_01: '#000000',
  black_02: 'rgba(0, 0, 0, 0.65)',
};

export default theme;
